import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import menu from './menu'
import { storage } from '@/utils/storage'
import layout from "@/layout/layout.vue";
import axios from 'axios';
const timestamp = Date.now()
const response = await axios.get(`/version.json?timestamp=${timestamp}`)
const version = response.data;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Root',
        component: layout,
        redirect: '/loan/apply',
        children: menu
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue')
    },
    {
        path: '/detail',
        name: 'detail',
        component: layout,
        children: [
            {
                path: '/loan/apply/:id',
                name: 'applyDetail',
                component: () => import('@/views/loan/applyDetail.vue'),
                meta: {
                    label: '贷款申请详情',
                    hideSideBar: true
                }
            },
            {
                path: '/loan/applyDetail/:proId/:id',
                name: 'applyDetail',
                component: () => import('@/views/loan/applyDetailNew.vue'),
                meta: {
                    label: '贷款申请详情',
                    hideSideBar: true
                }
            },
            {
                path: '/loan/in/:proId/:id/:applyId',
                name: 'inDetail',
                component: () => import('@/views/loan/inDetail.vue'),
                meta: {
                    label: '贷中详情',
                    hideSideBar: true
                }
            },
            {
                path: '/customer/detail/:id',
                name: 'customerDetail',
                component: () => import('@/views/customer/detail.vue'),
                meta: {
                    label: '客户详情',
                    hideSideBar: true
                }
            },
            {
                path: '/shop/detail/:sellerId/:userId',
                name: 'shopDetail',
                component: () => import('@/views/shop/detail.vue'),
                meta: {
                    label: '店铺详情',
                    hideSideBar: true
                }
            },
            {
                path: '/report/detail/:id',
                name: 'reportDetail',
                component: () => import('@/views/report/riskReport.vue'),
                meta: {
                    label: '报告详情',
                    hideSideBar: true
                }
            }
        ]
    }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
    const oldV = localStorage.getItem('version')
    if(oldV !== version.version) {
        //如果有更新，保存最新版本
        localStorage.setItem('version', version.version)
        //帮用户刷新页面
        window.location.reload();
    }
  if (to.name !== 'login' && !storage.get(process.env.VUE_APP_COOKIE_KEY)) next({ name: 'login' })
  else next()
})

export default router
