import request from './request'

/**
 * 获取登录验证码
 * @param params
 */
export const captcha = () => {
    return request({ url: '/sys/captcha', method: 'get' });
};

/**
 * 用户登录
 */
export const login = (params: any) => {
    return request({ url: '/sys/login', method: 'post', data: params });
};


/**
 * 风险性列表
 */
export const riskList = (params: any) => {
    return request({ url: '/risk/list', method: 'post', data: params });
};

/**
 * 发送手机验证码
 */
export const verifyCode = (mobile: string) => {
    return request({ url: '/customers/verifyCode/' + mobile, method: 'get', data: {} });
};

/**
 * 亚马逊站点集合
 */
export const getAmazonCountryList = () => {
    return request({ url: '/common/getAmazonCountryList', method: 'get' });
};
