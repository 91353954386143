import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _ctx.zhCN,
    theme: {
        components: {
          Button: {
            primaryColor: '#25b864'
          }
        }
      }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view),
      (_ctx.$store.state.loading !== 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_a_spin, { size: "large" })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["locale"]))
}